import { Container } from 'common/UI'
import { RichTextStoryblok } from 'common/types'
import { CMSRichText } from 'modules/shared'

type Props = {
  block: RichTextStoryblok
}

export const RichText = ({ block, ...props }: Props): JSX.Element => {
  const { richtext } = block
  return (
    <section {...props}>
      <CMSRichText
        richtext={richtext}
        renderer={(type, renderDefault) => {
          if (type === 'blok') {
            return renderDefault()
          } else {
            return <Container variant="typography">{renderDefault()}</Container>
          }
        }}
      />
    </section>
  )
}
